<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="px-0"
      >
        <div class="chart-box">
          <div>
            <div class="text-h3">
              Energy Consumption
            </div>
            <span> Charging power over time for this session</span>
          </div>
          <apexchart
            type="line"
            height="350"
            :options="chartOptions"
            :series="series"
          />
        </div>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        v-for="(row, i) in items"
        :key="i"
        cols="4"
        md="4"
        sm="6"
        class="mx-0 px-0"
      >
        <div
          class="box"
          :class="i !== 0 ? `ml-3` : ''"
        >
          <div class="header">
            <span
              class="head-icon"
              :class="`icon-${color[i]}`"
            >
              <v-icon>{{ row.icon }}</v-icon>
            </span>
            <span class="head-title">{{ row.title }}</span>
          </div>
          <div class="value">
            {{ row.value }}
          </div>
          <div class="sub-value">
            {{ row.desc }}
          </div>
        </div>
      </v-col>
    </v-row>
    <!--  -->
  </v-container>
</template>
<script>
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      color: ["blue", "yellow", "green"],
      items: [
        {
          icon: "mdi-lightning-bolt",
          title: "Peak Power",
          value: "43.4 kW",
          desc: "Maximum power during session",
        },
        {
          icon: "mdi-lightning-bolt",
          title: "Average Power",
          value: "47.2 kW",
          desc: "Average power during session",
        },
        {
          icon: "mdi-lightning-bolt",
          title: "Carbon Offset",
          value: "14.5 kg",
          desc: "CO₂ emissions saved",
        },
      ],
    };
  },
  computed: {
    series() {
      // const fc = this.items.map((r) => r.fuel_dispensed);
      // const dsc = this.items.map((r) => r.dispenser_sessions_count);
      const fc = [0, 45, 44, 42, 43, 40, 42, 43, 45, 0];
      const dsc = [0, 11, 13, 14, 15, 20, 30, 40, 50, 1844];
      return [
        {
          // name: this.$options.filters.currencyFormat(
          //   this.$t("fuel_dispensed"),
          //   this.authUser
          // ),
          name: "A",
          type: "area",
          data: fc,
        },
        {
          // name: "Dispenser Sessions (count)",
          name: "B",
          type: "area",
          data: dsc,
        },
      ];
    },
    chartOptions() {
      // const dLable = this.items.map((r) => r.date_only);
      const dLable = ["2025-03-16", "2025-03-16", "2025-03-17"];
      return {
        chart: {
          height: 350,
          type: "line",
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        // plotOptions: {
        //   bar: {
        //     columnWidth: "50%",
        //   },
        // },

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        // dataLabels: {
        //   enabled: true,
        //   enabledOnSeries: [1],
        // },

        labels: dLable,
        markers: {
          size: 0,
        },
        xaxis: {
          type: "string",
          labels: {
            formatter: function (value) {
              return moment(value).format("DD/MM");
            },
          },
        },
        yaxis: [
          {
            title: {
              // text: this.$options.filters.currencyFormat(
              //   this.$t("fuel_dispensed"),
              //   this.authUser
              // ),
              text: "A",
            },
          },
          {
            opposite: true,
            title: {
              // text: "Dispenser Sessions (count)",
              text: "B",
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y;
              }
              return y;
            },
          },
        },
      };
    },
  },
};
</script>
<style lang="sass" scoped>
.chart-box
  border: 1px solid lightgray
  border-radius: 5px
  padding: 1rem

.box
    height: 150px
    border: 1px solid lightgray
    border-radius: 5px
    padding: 1rem
    .header
        .icon-blue
            i
                color: #3b82f6 !important
        .icon-yellow
            i
                color: #f59e0b !important
        .icon-green
            i
                color: #22c55e !important
        .head-title
            color: #000
            padding-left: 5px
            font-weight: 600
            font-size: 14px
    .value
        padding-top: 5px
        color: #000
        font-size: 24px
        font-weight: 600
    .sub-value
        padding-top: 5px


.text-h3
  font-weight: 600
</style>
